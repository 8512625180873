﻿// MACRO.MAP

@use "sass:math";

.m-map {
	@include body-text__figure;
}

.m-map__embed {
	@include embed;

	padding-bottom: percentage(math.div(2, 3));
}
