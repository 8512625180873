﻿// MACRO.BLOCKQUOTE

.m-quote {
	@include body-text__figure;
}

.m-quote__text {
	@include font-base-20-tall;
}

.m-quote__citation {}
