﻿// CONTROL.FORM

@use "sass:math";

.c-form {
    @include control;

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    select,
    textarea {
        @include form-input;
    }

    select ~ .field-validation-error {
        border-color: $error;
    }

    textarea {
        height: 150px;
    }
}

.c-form__layout {
    @include layout-wrapper;
}

.c-form__heading {
    @include control__heading;
}

.c-form__field {
    margin-bottom: $bsu;

    &:last-child {
        margin-bottom: 0;
    }
}

.c-form__field label {
    @include font-base-14;
}

.c-form__required-indicator {
    color: $red;
}

.c-form__controls {
    margin-top: $bsu;
}

.c-form__control {
    @include button;
}

.validation-summary-valid {
    display: none;
}

.field-validation-error,
.c-form__error-message,
.validation-summary-errors {
    font-size: 13px;
    color: $error !important;
}

.c-form__error-message {
    display: none;
}

.has-error .c-form__error-message {
    display: block;
}

.umbraco-forms-navigation {
    text-align: right;
}

.titleanddescription {
    h2 {
        @include font-heading-32;
        margin-bottom: 0.5em;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}


// Fake inputs
.c-fake-checkbox {
    @include fake-input;

    &:checked ~ .c-fake-checkbox__display {
        &::before {
            border-color: $brand;
        }
    }

    &:focus ~ .c-fake-checkbox__display {
        @include focus-outline;
    }
}

.c-fake-checkbox__display {
    @include fake-input__display;

    @include link-transition;

    &::before {
        @include link-transition;
        content: '';
        position: absolute;
        left: 4px;
        top: 5px;
        display: block;
        width: 16px;
        height: 8px;
        border: 2px solid transparent;
        border-width: 0 0 2px 2px;
        background: transparent;
        transform: rotate(-45deg);
    }
}

// Fake Radio
.c-fake-radio {
    @include fake-input;

    &:checked ~ .c-fake-radio__display {
        &::before {
            background-color: $brand;
        }
    }

    &:focus ~ .c-fake-radio__display {
        @include focus-outline;
    }
}

.c-fake-radio__display {
    @include fake-input__display;
    border-radius: 25px;

    @include link-transition;

    &::before {
        @include link-transition;
        content: '';
        position: absolute;
        left: 3px;
        top: 3px;
        display: block;
        width: 17px;
        height: 17px;
        border-radius: 17px;
        background-color: transparent;
    }
}

// Fake file
.c-fake-file {
    @include fake-input;
}

.c-fake-file__display {
    @include fake-input__display;

    @include flex-extend;

    .c-fake-file:focus + & {
        @include focus-outline;
    }

    &.has-error {
        .c-fake-file__display-value {
            border-color: $error;
        }

        .c-fake-file__display-button {
            background: $error;
        }
    }

    cursor: pointer;

    .c-fake-file:disabled + & {
        cursor: not-allowed;

        .c-fake-file__display-value {
            background: $grey--light;
        }

        .c-fake-file__display-button {
            cursor: not-allowed;
            color: $white;

            &,
            &:hover,
            &:focus {
                background-color: $grey;
            }
        }
    }
}

.c-fake-file__display-value {
    @include form-input;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(.has-value) {
        color: $grey;
    }
}

.c-fake-file__display-button {
    margin-left: $ssu;

    @include flex-extend;
    align-items: center;
    justify-content: center;
}


/*====-=-------------  Umbraco Forms  -------------=-====*/

.umbraco-forms-form {
    @include control;
    max-width: 500px;
    margin: 0 auto;

    .umbraco-forms-field {
        margin-bottom: $bsu;

        .umbraco-forms-field-wrapper {
            clear: both;

            input[type="text"],
            input[type="password"],
            input[type="email"],
            input[type="tel"],
            select,
            textarea {
                @include form-input;
                max-width: 100% !important;
                margin: 0;
            }

            select ~ .field-validation-error {
                border-color: $error;
                margin-bottom: $tsu;
            }

            textarea {
                height: 150px;
            }

            .date & {
                position: relative;

                @include bp("small") {
                    max-width: 250px;
                }

                &:after {
                    position: absolute;
                    display: block;
                    right: $ssu;
                    top: 15px;
                    font-family: #{$icomoon-font-family};
                    content: $iconf-calendar;
                    pointer-events: none;
                }
            }

            .checkbox & {
                margin-top: 0;
            }

            .singlecheckbox & {
                order: -1;
                margin-right: $tsu;
            }

            .titleanddescription & {
                margin-top: 0;
            }

            .checkboxlist,
            .radiobuttonlist {
                label {
                    display: inline-block;
                    margin-bottom: math.div($ssu, 2);
                    margin-left: 3px;
                }

                label:last-of-type {
                    margin-bottom: 0;
                }
            }

            .checkboxlist {
                padding: $ssu 0 !important;
                float: none !important;

                label {
                    float: none !important;
                }
            }

            span.contourError,
            span.field-validation-error {
                color: #BF2C31 !important;
                margin: 0;
            }
        }

        &.checkbox {
            position: relative;
            padding-left: $bsu;

            input[type="checkbox"] {
                position: absolute;
                left: 0;
                top: $tsu;
            }
        }
    }

    .umbraco-forms-caption {
        @include font-base-18;
        margin-bottom: 1.5em;
    }

    .umbraco-forms-fieldset {
        @include ui-border('bottom');
        padding: 0;
        margin: 0;

        legend {
            padding-bottom: $bsu;
        }
    }

    .umbraco-forms-tooltip {
        font-size: 12px;
        font-style: italic;
        line-height: 1.5;
        color: #666;
        margin-top: -5px;
        display: block;
    }

    &:last-child {
        margin-bottom: 0;
    }



    .umbraco-forms-label-wrapper {
        order: 2;
    }

    .umbraco-forms-label, .c-form__label {
        @include font-base-14;
        line-height: 1.8;
        display: block;
    }

    .btn {
        @include button;

        @include bp("large") {
            @include button-size("medium");
        }
    }
}
