﻿// CONTROL.FILTER(in Listing)

@use "sass:math";

$filter-bp: "large";

.c-filter__control {
	@include control;
}

.c-filter__layout {
	@include layout-wrapper;
}

.c-filter {
	margin-bottom: $bsu;
}

.c-filter__form {
}

.c-filter__field {
	margin-bottom: $bsu;

	@include bp($filter-bp) {
		display: inline-flex;
		align-items: center;
	}
}

.c-filter__label {
	display: block;
	@include font-base-14b;
	margin-bottom: $tsu;

	@include bp($filter-bp) {
		margin-bottom: 0;
		margin-right: $ssu;
	}
}

.c-filter__dropdown {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 100%;

	select {
		@include ui-border('all');
		padding: $ssu $lsu $ssu $ssu;
		margin: 0;
		width: 100%;
		background: $grey--light;
		/*Hiding the select arrow for firefox*/
		-moz-appearance: none;
		/*Hiding the select arrow for chrome*/
		-webkit-appearance: none;
		/*Hiding the select arrow default implementation*/
		appearance: none;

		&::-ms-expand {
			display: none;
		}
	}

	&:before {
		@include font-icon;
		position: absolute;
		display: block;
		content: $iconf-arrow-down;
		right: $bsu;
		top: 50%;
		transform: translateY(-50%);
		color: $brand;
		pointer-events: none;
	}

	@include bp($filter-bp) {
		width: auto;
		min-width: 200px;
		margin-right: $bsu;
	}
}

.c-filter__input-wrap {
	position: relative;
}

.c-filter__input {
	@include ui-border('all');
	padding: $ssu $lsu $ssu $ssu;
	margin: 0;
	width: 100%;
	background: $grey--light;

	&[type="date"] {
		&::-webkit-calendar-picker-indicator {
			margin-right: ($bsu * -1.2);
			opacity: 0;
		}
	}
}

	.c-filter__input[list] {
		// Using dataList
		padding-right: $ssu;

		&::-webkit-calendar-picker-indicator {
			// Hide arrow in webkit browsers
			display: none;
		}
	}

.c-filter__input-icon {
	position: absolute;
	right: $bsu;
	top: 50%;
	transform: translateY(-50%);
	color: $brand;
	pointer-events: none;
}

// Checkbox list etc.
.c-filter__item-list {
	@include list-reset;
}

.c-filter__item {
	margin-bottom: $ssu;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-filter__item-label {
	@include flex-extend;
	@include fake-input__wrapper;

	align-items: center;
	cursor: pointer;
}

.c-filter__item-input {
	margin-right: $ssu;
}

.c-filter__item-label-text {
	@include font-base-14;
}

.c-filter__expand {
	.c-filter__expand-body {
		display: none;
	}

	.c-filter__heading {
		@include font-heading-24b;
		cursor: pointer;
	}

	.c-filter__heading--mobile {
		@include bp($filter-bp) {
			display: none;
		}
	}
	.c-filter__heading--desktop {
		@include bp-below($filter-bp) {
			display: none;
		}
	}

	&[aria-expanded="true"] {
		.c-filter__expand-body {
			display: block;
		}

		.c-filter__heading {
			margin-bottom: 0.7em;
		}
	}

	@include bp($filter-bp) {
		.c-filter__expand-body {
			display: block;
		}

		.c-filter__heading {
			margin-bottom: 0.7em;
			cursor: default;
		}
	}
}

// filter in sidebar
.c-filter__aside {
	@include ui-border('all');



	.c-filter__form {
		padding: $bsu;
	}

	.c-filter__field {
		display: block;
		width: 100%;
	}

	.c-filter__dropdown {
		margin-right: 0;
		width: 100%;
	}

	.c-filter__label {
		margin-bottom: math.div($tsu, 2);
	}

	.c-filter__label-inline {
		display: inline-block;
		margin-top: $ssu;
		vertical-align: middle;
		@include font-base-14;
		width: 50px;
	}

		.c-filter__label-inline + .c-filter__input-wrap {
			width: calc(100% - 55px);
			display: inline-block;
			vertical-align: middle;
			margin-top: $ssu;
		}

	.c-filter__controls {
		@include flex-extend;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
	}
}

.c-filter__submit {
	@include button;
}

.c-filter__reset {
	@include button;
	@include button-reset;
}


.c-filter__summary {
	@include list-reset;
	margin-bottom:15px;
	li {
		display: inline-block;
		margin-left: 10px;
	}
}

.c-filter__summary__title {
	font-weight: 600;
}

.c-filter__summary__item {
}

.c-filter__summary__link {
	color: #fff;
	background: var(--primary-a);
	padding: 6px 12px;
	opacity: 1;
	transition: opacity 0.2s ease-out;
	line-height: 1em;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&:after {
		content: $iconf-close;
		font-size: 0.7em;
		margin-left: 6px;
		display: inline-block;
	}

	&:hover,
	&:focus {
		opacity: 0.8;
	}
}
