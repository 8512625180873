﻿// CONTROL.IMAGE-CTA

@use "sass:math";

$image-cta-bp: "large";

.c-image-cta {
	@include control;
	@include radius;
}

.c-image-cta__layout {
	@include layout-wrapper;
}

.c-image-cta__viewport {
	height: 0;
	padding-bottom: 56.25%; //mobile use square crop for now
	overflow: hidden;
	position: relative;

	@include bp($image-cta-bp) {
		padding-bottom: percentage(math.div(384, 1366)); //desktop use hero crop for now
	}
}

.c-image-cta__item {
	width: 100%;
	position: absolute;
	height: 100%;
}

.c-image-cta__figure {
	@include figure;
}

.c-image-cta__image {}

.c-image-cta__image--mobile {
	@include bp($image-cta-bp) {
		display: none;
	}
}

.c-image-cta__image--desktop {
	@include bp-below($image-cta-bp) {
		display: none;
	}
}

.c-image-cta__body {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	background: rgba($black, 0.2);
	color: $white;
}

.c-image-cta__body-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	.c-image-cta__heading {
		@include control__heading;

		margin-bottom: $ssu;
		@include bp("small") {
			margin-bottom: $bsu;
		}
	}
}

.c-image-cta__abstract {
	margin-top: $ssu;
	@include font-base-14;

	@include bp("small") {
			margin-top: $bsu;
	}
}

.c-image-cta__btn {
	@include button;

	margin-top: $bsu;
}
