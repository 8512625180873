﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	@include layout-wrapper;
	margin-top: $lsu;
	margin-bottom: $lsu;
}

.c-breadcrumb__list {
	@include list-reset;
	@include list-inline;
}

.c-breadcrumb__link {
	@include font-base-12;
}

.c-breadcrumb__current {
	@include font-base-12;
	@include link-plain;
}
