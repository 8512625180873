﻿// MACRO.VIDEO

@use "sass:math";

.m-video {
	@include body-text__figure;
}

.m-video__embed {
	@include embed;

	padding-bottom: percentage(math.div(9, 16));
}

.m-video__figcaption {
	@include body-text__figcaption;
}
