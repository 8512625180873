// OBJECT.GRID-LAYOUT

@supports (display: grid) {
    .o-grid-layout {
        display: grid;
        grid-gap: $lsu;
        // When grid support exists, override any styles from o-grid,
        // o-grid__item, and utility width classes applied to o-grid-layout
        // and its direct children. These are all handled by CSS grid now
        margin-left: 0;
        margin-top: 0;

        > .o-grid__item {
            width: 100% !important;
            padding-left: 0;
            padding-top: 0;
        }

        > .c-pods__item {
            margin-bottom: 0;
            @include radius;
        }
    }
    // Repeatable column layouts
    .o-grid-layout--one {
        grid-template-columns: repeat(1, 1fr);
    }

    .o-grid-layout--two {
        grid-template-columns: repeat(1, 1fr);

        @include bp("medium") {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .o-grid-layout--three {
        grid-template-columns: repeat(1, 1fr);

        @include bp("medium") {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .o-grid-layout--four {
        grid-template-columns: repeat(1, 1fr);

        @include bp("medium") {
            grid-template-columns: repeat(2, 1fr);
        }

        @include bp("large") {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    // Alternating sets
    .o-grid-layout--two-four-alt {
        grid-template-columns: repeat(1, 1fr);

        @include bp("medium") {
            grid-template-columns: repeat(2, 1fr);
        }

        @include bp("large") {
            grid-template-columns: repeat(4, 1fr);

            > :nth-child(6n + 1),
            > :nth-child(6n + 2) {
                grid-column: span 2;
            }
        }
    }

    .o-grid-layout--four-two-alt {
        grid-template-columns: repeat(1, 1fr);

        @include bp("medium") {
            grid-template-columns: repeat(2, 1fr);
        }

        @include bp("large") {
            grid-template-columns: repeat(4, 1fr);

            > :nth-child(6n + 5),
            > :nth-child(6n) {
                grid-column: span 2;
            }
        }
    }

    .o-grid-layout--one-two-alt {
        grid-template-columns: repeat(1, 1fr);

        @include bp("medium") {
            grid-template-columns: repeat(2, 1fr);
        }

        @include bp("large") {
            grid-template-columns: repeat(4, 1fr);

            > :nth-child(6n + 1) {
                grid-column: span 2;
            }

            > :nth-child(6n) {
                grid-column: span 2;
            }
        }
    }

    .o-grid-layout--products {
        grid-template-columns: repeat(1, 1fr);

        @include bp('small') {
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        }
    }

    .o-grid-layout--experiences {
        grid-template-columns: repeat(1, 1fr);

        @include bp('small') {
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        }
    }
}
