// OBJECT.BTN

// Shared button styles

@mixin button-size($size: "medium", $icon: false) {
	@if ($icon == true) {
		@include button-icon-size($size);
	}
	@else {
		@if ($size == "large") {
			@include font-base-18b;
			padding: 24px $lsu;
			border-radius: 50px;
		}
		@else if ($size == "medium") {
			@include font-base-16b;
			padding: ($ssu * 1.5) ($ssu * 2);
			border-radius: 50px;
		}
		@else if ($size == "small") {
			@include font-base-14b;
			padding: 11px 17px;
			border-radius: 50px;
		}
		@else if ($size == "smallest") {
			@include font-base-14;
			padding: 6px 14px;
			border-radius: 50px;
		}
		@else {
			@warn "Unknown button size #{$size}.";
		}
	}
}

@mixin button-icon-size($size: "medium") {
	@if ($size == "large") {
		height: 74px;
		width: 74px;
		font-size: 28px;
		line-height: #{74px - (23px * 2)};
		padding: 23px;
	} @else if ($size == "medium") {
		height: 54px;
		width: 54px;
		font-size: 24px;
		line-height: #{54px - (15px * 2)};
		padding: 15px;
	} @else if ($size == "small") {
		height: 40px;
		width: 40px;
		font-size: 16px;
		line-height: #{40px - (11px * 2)};
		padding: 11px;
	} @else if ($size == "smallest") {
		height: 30px;
		width: 30px;
		font-size: 14px;
		line-height: #{30px - (6px * 2)};
		padding: 6px;
	}
}

@mixin button($size: "medium", $icon: false) {
	@include link-transition;
	display: inline-block;

	@if ($icon == true) {
		@include button-icon-size($size);
	}
	@else {
		@include button-size($size);
	}

	border-radius: 50px;
	border: 0;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
		background: $white !important;
		color: $grey !important;
		border: none !important;
		box-shadow: inset 0 0 0 1px $grey--light !important;
	}
}

@mixin button--text {
	padding: 0;
	border: none;
	background-color: transparent;
}

.o-btn-secondary {
	background: $blue;
	color: $white;

	&:hover {
		background: #343434;
		text-decoration: none;
		-webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
		box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
	}
}

.o-btn-outline {
	background: $white;
	border: 1px solid $blue;
	color: $green;

	&:hover {
		background: #EFEFEF;
		text-decoration: none;
		-webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
		box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
	}
}