﻿// COMPONENT.SEARCH-RESULT


.c-search-result__heading {
	margin-bottom: $bsu;

	.c-search-result__item & {
		margin-bottom: $ssu;
	}
}

.c-search-result__text {
	@include font-body-text;
	color: $body-color;
	margin-bottom: $ssu;
}