// OBJECT.CONTROL

@mixin control {
	@include bp-below("large") {
		margin-top: $lsu;
		margin-bottom: $lsu;
		margin-top: var(--control-gap);
		margin-bottom: var(--control-gap);
	}
	@include bp("large") {
		margin-top: $hsu;
		margin-bottom: $hsu;
		margin-top: var(--control-gap);
		margin-bottom: var(--control-gap);
	}
}

@mixin control__heading {
	@include font-base-20b;
	margin-bottom: $bsu;

	@include bp("large") {
		@include font-base-28b;
	}
}

@mixin control__figcaption {
	@include ui-border("bottom");
	@include font-body-text;
	padding: $bsu 0;
}
