// CONTROL.PEOPLE

@use "sass:math";

.c-people {
    @include control;
}

.c-people__layout {
    @include layout-wrapper;
}

.c-people__head {
}

.c-people__heading {
    @include control__heading;
    margin-bottom: $bsu;
}

.c-people__introduction {
    @include body-text;
    margin-bottom: $bsu;

    @include bp($layout-wrapper-max-width) {
        flex-shrink: 1;
    }
}

.c-people__list {
    @include list-reset;
    @include flex-extend;
    flex-wrap: wrap;
    align-items: stretch;
    margin-left: -$bsu;
    margin-top: -$bsu;
}

.c-people__item {
    padding-left: $bsu;
    padding-top: $bsu;
    width: percentage(math.div(1, 1));

    @include bp("small") {
        width: percentage(math.div(1, 2));
    }

    @include bp("medium") {
        width: percentage(math.div(1, 3));
    }

    @include bp("large") {
        width: percentage(math.div(1, 4));
    }

    .l-page--has-sidebar .l-page__main-controls & {
        @include bp("large") {
            width: percentage(math.div(1, 3));
        }
    }
}


// Person
.c-people__person {
    @include button-reset;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    isolation: isolate;

    &:hover,
    &:focus {
        // Focus outline applied on pseudoelement
        outline: none;

        &::before {
            content: "";
            position: absolute;
            z-index: 4;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &:focus {
        &::before {
            @include focus-outline();
        }
    }
}

.c-people__person-shape {
    @include radius;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba($black, 0.95) 0%, rgba($grey--darkest, 0) 30%);
    }
}

.c-people__person-image {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;
}

.c-people__person-body {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;

    @include flex-extend;
    align-items: flex-end;
    padding: $bsu;
    color: $white;
    text-align: left;
}

.c-people__person-content {
    flex-grow: 1;
}

.c-people__person-name {
    display: block;
    @include font-heading-24b;
}

.c-people__person-title {
    display: block;
    margin-top: $ssu;

    @include font-base-12b;
}

.c-people__person-icon {
    flex-shrink: 0;
    margin-left: $bsu;

    @include flex-extend;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    font-size: 25px;
    background: $green;

    @include link-transition;
    border-radius: 50%;
}
