﻿// MACRO.IMAGE

.m-image {
	@include body-text__figure;
}

.m-image__lightbox-trigger {
	@include figure;
	@include radius;

	position: relative;

	&::after {
		@include font-icon;
		content: $iconf-enlarge;
		position: absolute;

		bottom: $bsu;
		right: $bsu;
	}
}

.m-image__figure {
	@include figure;
	@include radius;
}

.m-image__figcaption {
	@include body-text__figcaption;
}
