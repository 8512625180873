﻿// COMPONENT.FEATURED-EVENT

.c-featured-event {
	@include control;
}

.c-featured-event__layout {
	@include layout-wrapper;
	margin-top: $bsu;
	margin-bottom: $bsu;
}

.c-featured-event__heading {
	@include control__heading;
}