$layout-page-bp: "large";

$layout-page-gutter: calc(max(0px, (var(--page-width) - #{$layout-wrapper-max-width}) / 2));
$layout-page-aside-column-width: calc(#{$layout-aside-gutter-width} + #{$layout-aside-width} + #{$layout-page-gutter} + #{$bsu});

// Fallbacks first
// Be sure to override within @supports block if necessary
.l-page {
}

.l-page__header {
}

.l-page__main-controls {
}

.l-page__sidebar {
	@include control;
	margin-left: $bsu;
	margin-right: $bsu;
	width: $layout-aside-width;
	--layout-gutter-left: 0;
	--layout-gutter-right: 0;
}

.l-page__footer-controls {
	margin-top: var(--control-gap);

	@include bp-below($layout-page-bp) {
		.l-page__sidebar + & {
			margin-top: 0;
		}
	}
}

.l-page__footer {
}

@supports (display: grid) {
	.l-page {
		display: grid;
		grid-template-areas:
			"header" "main-controls" "footer-controls" "sidebar" "footer";

		@include bp($layout-page-bp) {
			grid-template-columns: 1fr;

			&.l-page--has-sidebar {
				grid-template-columns: 1fr $layout-page-aside-column-width;
				grid-template-areas:
					"header header" "main-controls sidebar" "footer-controls footer-controls" "footer footer";
			}
		}
	}

	.l-page__header {
		grid-area: header;
	}

	.l-page__main-controls {
		grid-area: main-controls;
		margin-bottom: 40px;

		.l-page--has-sidebar & {
			@include bp($layout-wrapper-max-width) {
				margin-left: $layout-page-gutter;
				--layout-gutter-right: 0;
			}
		}
	}

	.l-page__footer-controls {
		grid-area: footer-controls;

		.l-page__main-controls + & {
			// Simulate control gap margin collapsing
			// like with display: block;
			margin-top: calc(-1 * var(--control-gap));
		}
	}

	.l-page__sidebar {
		grid-area: sidebar;

		@include bp($layout-page-bp) {
			width: $layout-aside-width;
		}

		width: auto;
		// If the sidebar is taller than the main controls,
		// ensure footer controls don't butt right up against it
		margin-bottom: var(--control-gap);

		@include bp($layout-page-bp) {
			margin-left: $layout-aside-gutter-width;
		}

		@include bp($layout-wrapper-max-width) {
			margin-right: calc(#{$layout-page-gutter} + #{$bsu});
		}
	}

	.l-page__footer {
		grid-area: footer;
	}
}
