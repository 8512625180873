﻿.c-homepage-intro {
    text-align: center;
    margin: 80px 0;

    .c-homepage-layout-wrapper {
        max-width: 1100px;
        margin: 0 auto;
        flex-grow: 1;
        padding: 0 20px;
        padding: 0 var(--layout-gutter-right) 0 var(--layout-gutter-left);

        .c-homepage-intro__title {
            @include font-heading-20b;
            margin-bottom: $bsu;
        }

        .c-homepage-intro__subtitle {
            @include font-heading-50b;
            margin-bottom: $bsu;
        }

        .c-homepage-intro__text {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: #333333;
        }
    }
}
