﻿// COMPONENT.FEATURED-NEWS

.c-featured-news {
	@include control;
}

.c-featured-news__layout {
	@include layout-wrapper;
	margin-top: $bsu;
	margin-bottom: $bsu;
}

.c-featured-news__heading {
	@include control__heading;
}