// COMPONENT.PAGINATION

@use "sass:math";

$pagination-bp: "medium";

.c-pagination {
	@include flex-extend;
	@include font-base-14;
	justify-content: center;
	align-items: center;
	margin-top: $bsu;
	margin-bottom: $bsu;
	text-align: center;

	@include bp("large") {
		margin-top: $lsu;
		margin-bottom: $lsu;
	}
}

.c-pagination__desktop {
	flex-grow: 1;

	@include grid;

	@include bp-below($pagination-bp) {
		display: none;
	}
}

.c-pagination__summary {
	padding-left: $bsu;
	width: percentage(math.div(1, 3));
}

.c-pagination__body {
	padding-left: $bsu;
	width: percentage(math.div(2, 3));
	text-align: right;
}

.c-pagination__list {
	@include list-reset;
	@include list-inline;
}

.c-pagination__mobile {
	text-align: center;
	@include bp($pagination-bp) {
		display: none;
	}
}

.c-pagination__current {
	padding: 0 $bsu;

	@include bp($pagination-bp) {
		padding: 0 $lsu;
	}
}

.c-pagination__dropdown {
	padding: $ssu;
	margin: 0 $tsu;
}

// "&, a" because the class is sometimes on an <li> and sometimes on an <a>
.c-pagination__prev,
.c-pagination__number,
.c-pagination__next {
	&, a {
		@include link-transition;
		@include font-base-16b;
		padding: 0 $tsu;
		white-space: nowrap;
	}

	&[aria-current]:not([aria-current="false"]) a {
		text-decoration: underline;
	}

	&:first-child {
		&, a {
			padding-left: 0;
		}
	}

	&:last-child {
		&, a {
			padding-right: 0;
		}
	}
}

.c-pagination__nav-text {
	display: none;

	@include bp("small") {
		display: inline;
	}
}
