// CONTROL.EXPERIENCE-CAROUSEL

@use "sass:math";

.c-experience-carousel {
	@include control;
}

.c-experience-carousel__layout {
	@include layout-wrapper;
}

.c-experience-carousel__heading {
	@include control__heading;
}

.c-experience-carousel__viewport {
	position: relative;
}

.c-experience-carousel__prev,
.c-experience-carousel__next {
	@include button("medium", true);

	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
}

.c-experience-carousel__prev {
	left: 0;
}

.c-experience-carousel__next {
	right: 0;
}

.c-experience-carousel__list {
	@include flex-extend;
	flex-wrap: wrap;
}

.c-experience-carousel__item {
	padding: 0 $ssu;

	width: 100%;
	@include bp("small") {
		width: percentage(math.div(1, 2));
	}
	@include bp("medium") {
		width: percentage(math.div(1, 3));
	}
	@include bp("large") {
		width: percentage(math.div(1, 2));
	}
	@include bp("extra-large") {
		width: percentage(math.div(1, 3));
	}
}

.c-experience-carousel__all {
	@include flex-extend;
	justify-content: center;

	margin-top: $bsu;
}

.c-experience-carousel__all-button {
	@include button;
}

.c-experience-carousel__list.flickity-enabled {
	display: block;

	@include bp("medium") {
		margin: 0 $lsu $lsu;

		.flickity-prev-next-button {
			&.previous {
				left: auto;
				right: 100%;
			}

			&.next {
				right: auto;
				left: 100%;
			}
		}
	}

	.flickity-viewport {
		&[style*="height"] {
			.c-experience-carousel__item {
				height: 100%;
			}
		}
	}
}
