﻿// CONTROL.MAILCHIMP

@use "sass:math";

.c-mailchimp {
	@include control;
}

.c-mailchimp__layout {
	@include layout-wrapper;
}

.c-mailchimp__heading {
	@include control__heading;
}