// OBJECT.EMBED

@use "sass:math";

@mixin embed {
	position: relative;
	overflow: hidden;
	height: 0;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}

	@media print {
		page-break-inside: avoid;
	}
}
