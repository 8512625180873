// COMPONENT.NAVIGATION

// Main Navigation
$navigation-bg: $white;
$navigation-bp: $header-bp;

.c-navigation {
	display: none;
	width: 100%;
	background-color: $navigation-bg;

	&[aria-expanded="true"] {
		display: block;
		position: absolute;
		top: $header-height - 45px;
		left: 0;
		height: calc(100vh - #{$header-height - 45px});
		overflow: auto;
	}

	@include bp($navigation-bp) {
		display: block;
		align-self: flex-end;
		width: auto;
		background-color: transparent;

		&[aria-expanded="true"] {
			display: block;
			position: absolute;
			top: $header-height - 45px;
			left: 0;
			height: calc(100vh - #{$header-height});
			height: calc(100vh - var(--header-height));
			overflow: auto;
		}
	}
}

.c-navigation__wrapper {
	height: calc(100% - 120px);
	overflow-y: scroll;

	@include bp($navigation-bp) {
		height: auto;
		overflow-y: auto;
	}
}

.c-navigation__items {
	@include list-reset;
	padding-top: $tsu;

	@include bp($navigation-bp) {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: stretch;
		padding: 0;
	}
}

.c-navigation__item {
	@include ui-border('bottom');
	border-color: $green;
	margin: 0 $bsu;

	@include bp($navigation-bp) {
		position: relative;
		border: 0;
		text-align: center;
		vertical-align: bottom;
		display: flex;
		align-items: stretch;
		margin: 0;
	}
}

.c-navigation__item--secondary {
	display: block;

	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__link {
	// Mobile Nav
	@include font-base-16b;
	display: block;
	padding: $ssu 0;
	color: $black;
	position: relative;
	// Mega Menu
	@include bp($navigation-bp) {
		display: inline-block;
		padding: 15px 0px 10px 30px;
		white-space: nowrap;
		color: $white;

		&:hover {
			color: $blue;
			transition: all 0.3s;

			.c-navigation__expander {
				background: url('../images/interface/expand-blue.svg') no-repeat center center;
				transition: all 0.3s;
			}
		}
	}

	.c-navigation__item--secondary & {
		@include font-base-16;
		color: $black;
	}
}

//  Nav open color change
.megamenu-is-open {
	.c-navigation__link {
		color: $black;

		.c-navigation__expander {
			background: url('../images/interface/expand-blue.svg') no-repeat center center;
			transition: all 0.3s;
			background-size: cover;
		}

		&:hover {
			color: $green;

			.c-navigation__expander {
				background: url('../images/interface/expand-green.svg') no-repeat center center;
				transition: all 0.3s;
				background-size: cover;
			}
		}
	}
}

.c-navigation__item[aria-expanded="true"] {
	.c-navigation__link {
		color: $brand;
	}
}

.c-navigation__item--megamenu {
	.c-navigation__link {
		@include bp($navigation-bp) {
			padding: 15px 0px 10px 30px;
		}
	}
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp($navigation-bp) {
		line-height: 1;
	}
}

.c-navigation__expander {
	height: 14px;
	width: 14px;
	display: inline-block;
	background-size: cover;
	margin-left: 5px;
	// Mobile Nav
	@include bp-below($navigation-bp) {
		background: url('../images/interface/expand-blue.svg') no-repeat center center;
		float: right;
		margin-top: 5px;

		.c-navigation__item[aria-expanded="true"] & {
			transform: rotate(135deg);
		}
	}
	// Mega Menu
	@include bp($navigation-bp) {
		background: url('../images/interface/expand-white.svg') no-repeat center center;
		background-size: cover;

		.c-navigation__item[aria-expanded="true"] & {
			background: url('../images/interface/expand-green.svg') no-repeat center center;
			background-size: cover;
			transform: rotate(-135deg);
		}
	}

	@include link-transition(transform);
	transform-origin: 50% 50%;
}

// Navigation Drop Down
.c-navigation-dropdown__items {
	@include list-reset;
	display: none;
	padding: $ssu 0 $ssu $bsu;
	width: 100%;
	background: $navigation-bg;

	@include bp($navigation-bp) {
		position: absolute;
		padding: 0;
		min-width: 250px;
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px 0 rgba($black, 0.2);
	}

	[aria-expanded="true"] > &,
	.no-js :hover > & {
		display: block;
	}
}

.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp($navigation-bp) {
		@include ui-border("bottom");
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	@include font-base-14b;
	display: block;
	padding: $tsu 0;
	color: $body-color;

	&::before {
		display: none;
	}

	@include bp($navigation-bp) {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-navigation__footer {
	padding: $lsu $bsu;

	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__social-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: -$bsu;
	margin-top: -$bsu;
}

.c-navigation__social-item {
	padding-left: $bsu;
	padding-top: $bsu;
}

.c-navigation__social-link {
	@include icon-wrapper;
	@include circle(40px);
	@include link-transition;
	font-size: 20px;
}
// Language container - append around used to insert language picker in here when it's visible
.c-navigation__language {
	padding: $bsu;

	@include bp('medium') {
		display: none;
	}
}
