// COMPONENT.HEADER

@use "sass:math";

$header-bp: "large";

$header-height: 130px;

:root {
	--header-height: #{$header-height};
}

.c-header {
	position: absolute;
	z-index: $z-header;
	height: ($header-height - 45px);
	width: 100%;
	max-width: $viewport-max-width;
	background-color: $brand;
	transition: all 0.25s ease-in-out;
	color: $white;

	@include bp("large") {
		height: $header-height;
		height: var(--header-height);
	}

	&.is-sticky {
		position: fixed;
		top: 0;
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);

		&.is-up {
			transform: translateY(calc(-100% - 6px));

			&.is-open {
				transform: none;
			}
		}
	}
}

.c-header__spacer {
	height: ($header-height - 45px);

	@include bp("large") {
		height: $header-height;
		height: var(--header-height);
	}
}

.c-header__wrapper {
	@include layout-wrapper;
	@include flex-extend;
	align-items: center;
	justify-content: space-between;
	padding: 20px;

	@include bp("large") {
		padding: 30px 20px;
	}
}

.c-header__logo {
	display: flex;
	text-align: center;
	height: 45px;
	width: 210px;

	@include bp("large") {
		height: 70px;
		width: 335px;
	}

	img {
		width: 100%;
		vertical-align: middle;
		align-self: center;

		&.c-header__logo-color {
			display: none;
		}

		&.c-header__logo-white {
			display: block;
		}
	}

	&:focus {
		@include focus-outline;
	}
}
//  Nav open color change
.megamenu-is-open {
	background-color: $white;

	.c-header__logo-color {
		display: block !important;
	}

	.c-header__logo-white {
		display: none !important;
	}

	.c-header__secondary-nav-link {
		color: $black;
		@include link-transition;

		&:hover {
			color: $green;
		}
	}
}

.c-header__tools {
	@include flex-extend;
	flex: 1 0 auto;
	align-items: center;
	justify-content: flex-end;
	order: 3;
	height: 20px;
}

// Secondary Nav
.c-header__secondary-nav {
	@include bp-below($header-bp) {
		display: none;
	}

	@include bp($header-bp) {
		@include list-reset;
		display: flex;
	}
}

.c-header__secondary-nav-item {
	padding-left: $bsu;
}

.c-header__secondary-nav-link {
	@include font-base-14;
	@include link-transition;
	display: block;
	color: $white;

	&:hover {
		color: $black;
	}
}

// Language
.c-header__language {
	display: none;

	@include bp("medium") {
		display: block;
		padding-right: $bsu;
	}
}

.c-header__language-dropdown {
	@include font-base-16;
	display: block;
	padding: 0.65em;
	border: 1px solid $grey;
}

// Mobile buttons
$mobile-button-size: 45px;
$mobile-button-color: $brand;
$mobile-button--hover-color: darken($brand, 10);
$mobile-button-font-size: 25px;
$mobile-button-padding: math.div(($mobile-button-size - $mobile-button-font-size), 2);

.c-header__mobile-buttons {
	flex: 1 0 auto;
	text-align: right;

	@include bp("medium") {
		flex: 0 0 auto;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	border: 0;
	background: none;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}
// Nav Toggle
.c-header__nav-toggle {
	@include button-reset;

	@include bp($header-bp) {
		display: none;
	}

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 0;
		border-top: 3px solid $white;
		border-radius: 10px;

		&:nth-child(1) {
			top: $mobile-button-padding;
		}

		&:nth-child(2) {
			top: $mobile-button-padding + 12px;
		}

		&:nth-child(3) {
			top: $mobile-button-padding + 24px;
		}
	}

	&[aria-expanded="true"] {

		b {
			&:nth-child(1) {
				transform: translateY(12px) rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-12px) rotate(-45deg);
			}
		}
	}
}
// Search Toggle
.c-header__search-toggle {
	@include button-reset;
	font-size: $mobile-button-font-size;
	color: $mobile-button-color;

	&[aria-expanded="true"] .iconf-search {
		&:before {
			content: $iconf-close;
		}
	}

	.iconf-search {
		position: absolute;
		left: $mobile-button-padding;
		top: $mobile-button-padding;
	}
}

.c-header__search {
	display: none;
	position: absolute;
	background: $white;
	top: $header-height;
	top: var(--header-height);
	left: 0;
	width: 100%;
	height: calc(100vh - #{$header-height});
	height: calc(100vh - var(--header-height));
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);

	@include bp($header-bp) {
		position: absolute;
		left: 0;
		top: $header-height;
		top: var(--header-height);
		height: auto;
	}
}

.c-header__search-wrapper {
	width: 65%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-bottom: 20px;

	@include bp($header-bp) {
		width: 700px;
		position: static;
		padding: 50px 0 80px;
		margin: 0 auto;
		top: auto;
		left: auto;
		transform: none;
	}
}

.c-header__search-field {
	position: relative;
}

.c-header__search-input {
	@include font-base-16;
	@include placeholder-text($grey);
	display: block;
	padding: 0.3em 0.5em;
	width: 100%;
	margin: 0 auto;
	border: none;
	border-bottom: 1px solid $grey;
	border-radius: 0;
	background: transparent;

	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

.c-header__search-btn {
	@include button-reset;
	position: absolute;
	padding: 0;
	width: 50px;
	height: 50px;
	right: 0;
	top: -7px;
	font-size: 26px;
	line-height: 1;
	color: $brand;

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include link-transition;

	&:hover,
	&:focus {
		color: darken($brand, 10%);
	}
}

.c-header__log-in {
	@include bp-below($header-bp) {
		display: none;
	}
}
