// BASE.TYPOGRAPHY

// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes
$base-font-family: "Open Sans", Arial, Helvetica, sans-serif;
$heading-font-family: "Rubik", Arial, Helvetica, sans-serif;
$base-font-size: 16px;
$base-line-height: 22px;

$weight--light: 300;
$weight--normal: 400;
$weight--semibold: 600;
$weight--bold: 700;

///////////////////////
// Custom @font-face //
//      example      //
///////////////////////

// Montserrat
//
// URL: https://fonts.google.com/specimen/Montserrat
// Transformed: Generated by Font Squirrel (https://www.fontsquirrel.com) on August 6, 2021
//
// Licence: SIL OPEN FONT LICENSE Version 1.1
// Licence URL: /Content/fonts/montserrat/OFL.txt

// @font-face {
// 	font-family: "Montserrat";
// 	src: url("/content/fonts/montserrat/montserrat-regular.eot");
// 	src: url("/content/fonts/montserrat/montserrat-regular.eot?#iefix") format("embedded-opentype"),
// 		url("/content/fonts/montserrat/montserrat-regular.woff2") format("woff2"),
// 		url("/content/fonts/montserrat/montserrat-regular.woff") format("woff"),
// 		url("/content/fonts/montserrat/montserrat-regular.ttf") format("truetype"),
// 		url("/content/fonts/montserrat/montserrat-regular.svg#montserratregular") format("svg");
// 	font-weight: $weight--normal;
// 	font-style: normal;
// 	font-display: auto;
// }

//////////

// Style declarations
//
//	.c-component__text {
//		@include font-base-12;
//	}

@mixin font-body-text {
	font-family: $base-font-family;
	font-size: 15px;
	font-weight: $weight--normal;
	line-height: lh(26px, 15px);
}

////////////////////////
// Base font variants //
////////////////////////

@mixin font-base-28b {
	font-family: $base-font-family;
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: lh(36, 28);
}

@mixin font-base-24b {
	font-family: $base-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-base-20 {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-base-20-tall {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
}

@mixin font-base-20b {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: lh(28, 20);
}

@mixin font-base-18 {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: 1.35;
}

@mixin font-base-18b {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
}

@mixin font-base-16 {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-base-16b {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-base-14 {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: 1.5;
}

@mixin font-base-14b {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
}

@mixin font-base-14u {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	text-transform: uppercase;
	line-height: 1.15;
}

@mixin font-base-12 {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-base-12b {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: lh(17, 12);
}

@mixin font-base-10b {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--bold;
	line-height: lh(14, 10);
}

///////////////////////////
// Heading font variants //
///////////////////////////

@mixin font-heading-60b {
	font-family: $heading-font-family;
	font-size: 60px;
	font-weight: $weight--bold;
	line-height: 1;
}

@mixin font-heading-50b {
	font-family: $heading-font-family;
	font-size: 50px;
	font-weight: $weight--bold;
	line-height: 1;
}

@mixin font-heading-36b {
	font-family: $heading-font-family;
	font-size: 36px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-32 {
	font-family: $heading-font-family;
	font-size: 32px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-heading-32b {
	font-family: $heading-font-family;
	font-size: 32px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-24b {
	font-family: $heading-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-20 {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-heading-20-tall {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
}

@mixin font-heading-20b {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-18b {
	font-family: $heading-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
}

@mixin font-heading-14b {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
}

@mixin font-heading-12b {
	font-family: $heading-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: 1.5;
}

html {
	font-family: $base-font-family;
	color: $body-color;
}

p {
	margin-top: 0;
}
