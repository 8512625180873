// CONTROL.EXPLORE-PODS

@use "sass:math";

@mixin explore-pods-wide {
	.c-explore-pods__pod-image-fallback,
	.c-explore-pods__pod-image {
		padding-bottom: percentage(math.div(370, 640));
		@include bp("large") {
			padding-bottom: percentage(math.div(37, 64));
		}
	}
	.c-explore-pods__pod-image--wide {
		display: block;
	}
	.c-explore-pods__pod-image--squat {
		display: none;
	}
}

@mixin explore-pods-squat {
	.c-explore-pods__pod-image-fallback,
	.c-explore-pods__pod-image {
		padding-bottom: 100%; // Square
		@include bp("medium") {
			padding-bottom: percentage(math.div(35, 42));
		}
	}
	.c-explore-pods__pod-image--wide {
		display: none;
	}
	.c-explore-pods__pod-image--squat {
		display: block;
	}
}

@mixin explore-pods-appear {
	@media screen and (prefers-reduced-motion: no-preference) {
		.c-explore-pods__pod,
		.c-explore-pods__cta {
			@content;

			opacity: 0;
			@include link-transition(all, 0.5s);
		}

		&[data-scroll-appear="true"] {
			.c-explore-pods__pod,
			.c-explore-pods__cta {
				opacity: 1;
				transform: none;
			}
		}
	}
}

.c-explore-pods {
	@include control;
}

.c-explore-pods__layout {
	@include layout-wrapper;
}

.c-explore-pods__top {
	@include flex-extend;
	flex-wrap: wrap;

	margin-left: -$bsu;
	margin-top: -$bsu;
	@include bp("large") {
		margin-left: -$lsu;
		margin-top: -$lsu;
	}

	justify-content: space-between;

	.c-explore-pods__heading {
		display: block;
		width: 100%;
		padding-left: $bsu;
		margin-bottom: $bsu;

		@include bp("large") {
			padding-left: $lsu;
		}

		@include font-heading-24b;
		@include bp("medium") {
			@include font-heading-32b;
		}
		@include bp("large") {
			@include font-heading-36b;
		}
	}

	.c-explore-pods__item {
		@include flex-extend;
		align-items: flex-end;
		@include explore-pods-squat;
	}
	.c-explore-pods__pod {
		width: 100%;
		height: auto;
	}

	.c-explore-pods__item {
		width: percentage(math.div(1, 2));
	}

	.c-explore-pods__head {
		width: percentage(math.div(1, 1));
	}

	@include bp("extra-large") {
		.c-explore-pods__item {
			width: percentage(math.div(1, 4));
		}

		.c-explore-pods__head {
			width: percentage(math.div(1, 2));
		}
	}
}

.c-explore-pods__bottom {
	@include flex-extend;
	align-items: flex-start;
	flex-wrap: wrap;

	margin-left: -$bsu;
	margin-top: (-$bsu + $bsu);
	@include bp("large") {
		margin-left: -$lsu;
		margin-top: (-$lsu + $lsu);
	}

	@include explore-pods-squat;
	.c-explore-pods__item {
		width: percentage(math.div(1, 2));
	}

	@include bp("large") {
		.c-explore-pods__item {
			width: percentage(math.div(1, 4));

			&:nth-child(2) {
				width: percentage(math.div(1, 2));
				@include explore-pods-wide;
			}
		}
	}
}

.c-explore-pods__head {
	flex-grow: 1;

	padding-left: $bsu;
	padding-top: $bsu;
	@include bp("large") {
		padding-left: $lsu;
		padding-top: $lsu;
	}
}

.c-explore-pods__head-content {
	width: 100%;
	@include bp("extra-large") {
		margin: 0 auto;
	}
}

.c-explore-pods__introduction {}

.c-explore-pods__introduction-links {
	display: flex;
	margin-top: $bsu;
}

.c-explore-pods__introduction-link {
	@include button("medium");
	display: flex;
	align-items: center;
	margin-top: #{$ssu - $tsu};
	margin-right: $lsu;

	&:last-child {
		margin-right: 0;
	}
}

.c-explore-pods__introduction-text {
	@include body-text;
}

.c-explore-pods__item {
	padding-left: $bsu;
	padding-top: $bsu;
	@include bp("large") {
		padding-left: $lsu;
		padding-top: $lsu;
	}

	@include explore-pods-appear {
		transform: translateY(100px);
	}
}


// Pod
.c-explore-pods__pod {
	display: block;
	position: relative;

	height: 100%;

	border-radius: 4px;
	overflow: hidden;

	// Create stacking context
	filter: grayscale(0);

	&:hover,
	&:focus {
		.c-explore-pods__pod-image {
			transform: scale(1.1);
		}

		.c-explore-pods__pod-title-text::after {
			opacity: 1;
		}
	}
}

.c-explore-pods__pod-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;

	position: relative;
	z-index: 1;

	@include link-transition(transform);
}

.c-explore-pods__pod-shadow {
	content: "";
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 150px;

	background: linear-gradient(to top, rgba($black, 0.95) 0%, rgba($grey--darkest, 0) 100%);
	background: linear-gradient(to top, var(--primary-a) 0%, transparent 100%);
}

.c-explore-pods__pod-image-fallback {
	background: $grey--dark;
	height: 100%;
}

.c-explore-pods__pod-body {
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 0;
	width: 100%;

	color: $white;
	padding: $ssu;
	@include bp("medium") {
		padding: $bsu;
	}
}

.c-explore-pods__pod-title {
	display: block;
	padding-right: calc(1em + #{$tsu});
	margin-top: $bsu;

	@include font-heading-18b;
	@include bp("large") {
		//@include font-heading-22b;
	}
}

.c-explore-pods__pod-title-text {
	//@include link-arrow;
}

/////////////////
// Grid layout //
/////////////////
@supports (display: grid) {
	@include explore-pods-wide;

	.c-explore-pods__top {
		display: grid;
		margin: 0;
		grid-gap: $bsu;

		@include bp("medium") {
			grid-gap: $lsu;
		}

		@include bp("large") {
			grid-gap: $hsu;
		}

		.c-explore-pods__heading,
		.c-explore-pods__item,
		.c-explore-pods__head {
			width: 100%;
			padding: 0;
		}

		grid-template-columns: 1fr;
		grid-template-areas:
			"head"
			".";

		@include bp("extra-large") {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
				". head";
		}

		.c-explore-pods__heading {
		}

		.c-explore-pods__head {
			grid-area: head;
		}

		.c-explore-pods__item {
			@include explore-pods-wide;
		}
	}

	.c-explore-pods__bottom {
		display: grid;
		grid-gap: $bsu;
		margin: $bsu 0 0;

		@include bp("medium") {
			grid-gap: $bsu;
			margin-top: $bsu;
		}

		@include bp("large") {
			grid-gap: $lsu;
			margin-top: $lsu;
		}

		.c-explore-pods__item {
			&,
			&:nth-child(2) {
				width: 100%;
			}

			padding: 0;
			width: auto;

			.c-explore-pods__pod {
				height: auto;
			}
		}

		grid-template-columns: 1fr 1fr;

		.c-explore-pods__item {
			&:nth-child(3) {
				grid-column-start: 1;
				grid-column-end: span 2;
				@include explore-pods-wide;
			}
		}

		@include bp("large") {
			grid-template-columns: 1fr 2fr 1fr;

			.c-explore-pods__item {
				&:nth-child(1) {
					grid-column-start: 1;
					grid-column-end: span 1;
					grid-row-start: 1;
					grid-row-end: span 1;
				}

				&:nth-child(2) {
					grid-column-start: 2;
					grid-column-end: span 1;
					grid-row-start: 1;
					grid-row-end: span 2;
				}

				&:nth-child(3) {
					grid-column-start: 3;
					grid-column-end: span 1;
					grid-row-start: 1;
					grid-row-end: span 1;
				}

				&:nth-child(1),
				&:nth-child(3) {
					@include explore-pods-squat;
				}

				&:nth-child(2) {
					@include explore-pods-wide;
				}
			}
		}
	}
}
