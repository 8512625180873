// CONTROL.FACTS-FIGURES

@use "sass:math";

$facts-figures-gap: $bsu;

.c-facts-figures {
    @include control;
    background: #F9F9F9;
    padding: $bsu*2 0 $bsu;

    @include bp("small") {
        padding: $lsu+20px 0 $lsu;
    }

    @include bp("large") {
        padding: $hsu+20px 0 $hsu;
    }
}

.c-facts-figures__layout {
    @include layout-wrapper;

    .l-page--has-sidebar .l-page__main-controls & {
        @include bp($layout-page-bp) {
            padding: 0 $lsu;
        }
    }
}

.c-facts-figures__heading {
    @include control__heading;
}

.c-facts-figures__item-list {
    @include list-reset;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: calc(100% + $ssu*2);
    margin-left: -$ssu;
    margin-top: $bsu - $facts-figures-gap;

    @include bp("large") {
        margin-top: $lsu - $facts-figures-gap;
    }
}

.c-facts-figures__item {
    @include scroll-appear {
        opacity: 0;
        transform: translateY(100px);
    }

    @include link-transition("opacity, transform", 0.6s);
    display: flex;
    align-items: stretch;
    position: relative;
    padding: $ssu;

    @include bp-below("medium") {
        width: 100%;

        &:nth-child(n+1)::after {
            display: none;
        }
    }

    @include bp("medium") {
        @include bp-below("large") {
            width: 50%;
        }
    }

    @include bp("large") {
        width: 33.33%;
    }
}

.c-facts-figures__pod {
    @include link-transition("color, background-color");
    display: flex;
    width: 100%;
    margin: $bsu 0;
    flex-direction: column;

}

.c-facts-figures__item-title {
    display: block;
    color: $green;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 90px;
    line-height: 125px;
    letter-spacing: -0.03em;
    word-break: break-word;
}

.c-facts-figures__item-text {
    flex-grow: 1;
    display: block;
    margin-top: $bsu;
    color: #2A302B;
    @include font-body-text;
    font-weight: bold;
}

.c-facts-figures__item-link {
    display: block;
    margin-top: $bsu;
    color: $green;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    transition: all 0.3s;

    @include underline-animate;

    &:hover {
        color: #01923e;
        transition: all 0.3s;
    }
}
