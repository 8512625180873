﻿// CONTROL.CTA

.c-cta {
    @include control;
    background: $yellow;
}

.c-cta__layout {
    @include layout-wrapper;
}

.c-cta--dark {
    background: $green;

    .c-cta__heading,
    .c-cta__text {
        color: $white;
    }
}

.c-cta__body {
    position: relative;
    padding: $bsu 0 $bsu*3.5;
    margin: 100px 0 $bsu;
    text-align: center;
    overflow: visible;

    @include bp("medium") {
        @include flex-extend;
        flex-direction: row;
        justify-content: space-between;
        overflow: visible;
        padding: ($bsu * 3) 0;
        align-items: center;
        text-align: left;
        margin: ($hsu + $hsu) 0 $bsu;
/*
        &::before {
            outline: 1px red solid;
            right: auto;
            left: ($bsu * 2);
            transform: translateX(0);
        }*/
    }
}

.c-cta__body-image {
    width: 60%;
    max-width: 178px;
    margin: 0 $bsu $bsu $bsu;

    @include bp("large") {
        margin: 0 $lsu $bsu $bsu;
        width: 230px;
        flex: 0 0 230px;
    }

    img {
        width: 100%;
        margin-top: -65px;

        @include bp("medium") {
            margin-top: -165px;
        }

        @include bp("large") {
            margin-top: -115px;
        }
    }

    .c-cta__body-image-dark {
        display: none;
    }

    .c-cta__body-image-light {
        display: block;

        @include bp("medium") {
            display: inline-block;
        }
    }

    .c-cta--dark & {
        .c-cta__body-image-dark {
            display: block;

            @include bp("medium") {
                display: inline-block;
            }
        }

        .c-cta__body-image-light {
            display: none;
        }
    }
}

.c-cta__body-wrapper {
    margin-bottom: $bsu;
    flex: 1 1 auto;

    @include bp("medium") {

        .o-layout-main & {
            flex: 1 1 auto;
            padding-right: $lsu;
            margin-bottom: 0;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-cta__heading {
    @include font-heading-32b;
    margin-bottom: 0.5em;
    color: $body-color;

    @include bp("large") {
        @include font-heading-36b;
    }
}

.c-cta__text {
    @include font-base-20-tall;
    margin-bottom: 2em;
    margin-right: $bsu;
    color: $body-color;
}

.c-cta__link {
    @include button("medium");
    margin-right: $bsu;

    @include bp("medium") {
        white-space: nowrap;
        flex: 0 0 160px;
    }
}
