// COMPONENT.BASIC-FILTER

.c-basic-filter {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;

	margin-left: -$bsu;
	margin-top: -$bsu;
	margin-bottom: $bsu;
}

.c-basic-filter__item {
	padding-left: $bsu;
	padding-top: $bsu;
}

.c-basic-filter__button {
	@include button("small");
}
