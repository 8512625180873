/* COMPONENT.MEGAMENU */

$megamemu__intro-width: 400px;

$megamenu__column-gap: 50px;
$megamenu__column-number: 4;
$megamenu__column-number--with-promos: 2;
$megamenu__column-number--with-promos-vars: calc(4 - var(--megamenu-number-promos, 2));

$megamenu-bp: $header-bp;

.c-megamenu {
    --megamenu-column-gap: 50px;

    @include bp("extra-large") {
        --megamenu-column-gap: 110px;
    }

    height: 0;
    max-height: 0;
    transition: max-height 0.4s;
    background: $white;
    overflow:hidden;

    &[aria-expanded="true"],
    [aria-expanded="true"] > & {
        height:auto;
        max-height: 1000px;
        transition: max-height 0.4s;
    }

    &[aria-expanded="true"] {
        box-shadow: 0 8px 6px -6px rgba($black, 0.2);
    }

    @include bp($megamenu-bp) {
        transition: max-height 0.5s ease-in-out;
        position: absolute;
        background: $grey--lightest;
        top: $header-height;
        top: var(--header-height);
        left: 0;
        display: block;
        width: 100%;
        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        text-align: left;
        box-shadow: 0 8px 6px -6px rgba($black, 0.2);

        .c-navigation__item--megamenu[aria-expanded="true"] & {
            z-index: 1;
        }

        &[aria-expanded="true"],
        [aria-expanded="true"] > & {
            max-height: 500px;
            max-height: calc(100vw - var(--header-height));
            visibility: visible;
        }

        &.allow-animations,
        .allow-animations > & {
            animation: 0.5s becomeHidden forwards;
        }

        &.allow-animations[aria-expanded="true"],
        .allow-animations[aria-expanded="true"] > & {
            animation: 0.5s becomeVisible forwards;
        }

        .no-js .c-navigation__item:hover & {
            display: block;
        }
    }
}

.c-megamenu__wrapper {
    @include clearfix;

    @include bp($megamenu-bp) {
        max-width: em($layout-wrapper-max-width);
        padding: 30px $bsu;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
}

.c-megamenu__body {
    @include bp($megamenu-bp) {
        @include flex-extend;
    }
}

.c-megamenu__body--single-promo {
    --megamenu-number-promos: 1;
}

.c-megamenu__intro {
    width: 100%;
    display: none;

    @include bp($megamenu-bp) {
        display: block;
    }
}

.c-megamenu__intro-heading {
    display: inline-block;
    margin-bottom: 10px;
    padding: 0;
    color: $black;
    transition: all 0.3s;

    @include font-base-28b;

    &:hover {
        color: $green;
        transition: all 0.3s;
    }
}

.c-megamenu__items {
    @include list-reset;
    flex-grow: 1;

    @include bp($megamenu-bp) {
        width: 100%;
        float: right;
        columns: $megamenu__column-number--with-promos;
        columns: $megamenu__column-number--with-promos-vars;
        padding: 0 $megamenu__column-gap 0 0;
        padding: 0 var(--megamenu-column-gap) 0 0;

        &:last-child {
            // No promos
            columns: $megamenu__column-number;
            padding-right: 0;
        }

        column-gap: $megamenu__column-gap;
        column-gap: var(--megamenu-column-gap);
    }
}

.c-megamenu__item {
    display: inline-block;
    width: 100%;
    margin-bottom: $ssu;

    @include bp($megamenu-bp) {
        display: block;
        margin: 0;
        break-inside: avoid;
    }
}

.c-megamenu__item--overview {
    @include bp($megamenu-bp) {
        display: none;
    }
}

.c-navigation__item {
    &.c-navigation__item--megamenu {
        @include bp($megamenu-bp) {
            position: static;
        }
    }
}

.c-megamenu__heading {
    position: relative;
}

.c-megamenu__link {
    color: $black;
    display: block;
    padding: $tsu 0;
    position: relative;
    transition: all 0.3s;

    &:before {
        display: none;
    }

    @include bp($megamenu-bp) {
        padding: $ssu $bsu $ssu 0;
        font-size: 14px;
        font-weight: $weight--bold;

        &:hover {
            color: $green;
            transition: all 0.3s;
        }

        &::before {
            @include abs-center-v;
            right: $bsu;
            display: block;
            color: $brand--alt;
            opacity: 0;
            transition: all 0.3s ease;
        }

        &:hover::before {
            right: $ssu;
            opacity: 1;
        }

        &::after {
            display: block;
            content: "";
            width: 0;
            display: block;
            opacity: 0;
            transition: all 0.3s ease;
            height: 2px;
            margin-top: $ssu;
        }

        &:focus::after,
        &:hover::after {
            width: calc(100% + #{$bsu});
            opacity: 1;
        }
    }
}

.c-megamenu__link-icon {
    &::before {
        @include font-icon;
        content: $iconf-arrow-right;
    }

    display: none;

    @include bp($megamenu-bp) {
        position: absolute;
        right: 0;
        opacity: 0;
        transition: all 0.3s ease;
        display: block;
        top: 50%;
        transform: translateY(-50%);

        .c-megamenu__link:hover &,
        .c-megamenu__link:focus & {
            opacity: 1;
        }
    }
}

.c-megamenu__promo-list {
    display: none;

    @include bp($megamenu-bp) {
        // Pretend the last column has a gap as well, divide the full width, then account for that extra gap
        $megamenu__column-width: calc(((100% + #{$megamenu__column-gap}) / #{$megamenu__column-number}) - #{$megamenu__column-gap});
        $megamenu__column-width-vars: calc(((100% + var(--megamenu-column-gap)) / #{$megamenu__column-number}) - #{$megamenu__column-gap});

        // Difference between normal and reduced number of columns
        $megamenu__column-number--promos: $megamenu__column-number - $megamenu__column-number--with-promos;
        $megamenu__column-number--promos-vars: var(--megamenu-number-promos, #{$megamenu__column-number - $megamenu__column-number--with-promos});

        // Column width for each column space taken, plus the appropriate number of gaps between columns
        $megamenu__promo-width: calc((#{$megamenu__column-width} * #{$megamenu__column-number--promos}) + calc(#{$megamenu__column-gap} * calc(#{$megamenu__column-number--promos} - 1)));
        $megamenu__promo-width-vars: calc((#{$megamenu__column-width-vars} * #{$megamenu__column-number--promos-vars}) + calc(#{$megamenu__column-gap} * calc(#{$megamenu__column-number--promos-vars} - 1)));

        width: $megamenu__promo-width;
        width: $megamenu__promo-width-vars;
        flex-shrink: 0;

        @include flex-extend;
    }
}

.c-megamenu__promo-item {
    @include link-transition;

    &:hover {
        opacity: .7;
    }

    @include bp($megamenu-bp) {
        width: calc(((100% + #{$bsu}) / 2) - #{$bsu});
        width: calc(((100% + #{$bsu}) / var(--megamenu-number-promos, 2)) - #{$bsu});
        margin-left: $bsu;

        &:first-child {
            margin-left: 0;
        }
    }
}

.c-megamenu__promo-image {
    border-radius: 4px;
    overflow: hidden;
}

.c-megamenu__promo-title {
    display: none;
}
