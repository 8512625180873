﻿/* UTILITY.HELPERS */

// Floats
.u-float--left {
	float: left !important;
}

.u-float--right {
	float: right !important;
}

// Self clear
// Retaining .clearfix as some other plugins & Umbraco use this
.clearfix {
	@include clearfix;
}

// Todo styles
@keyframes todoPulse {
	0% {
		opacity: 1;
	}

	90% {
		opacity: 1;
	}

	95% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

html:not(.todo-hide) {
	.todo,
	.todo-fe,
	.todo-be {
		animation: 5s todoPulse linear infinite;
		min-height: 10px;
		min-width: 10px;
	}

	.todo-fe {
		background: #FA0 !important;
	}

	.todo-be {
		background: #0f0 !important;
	}

	.todo,
	.todo-fe.todo-be {
		background: fuchsia !important;
	}
}
