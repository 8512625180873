﻿// CONTROL.PODS

$pod-bp: "large";

.c-pods {
    @include control;
    margin: 20px 0;

    .o-grid-layout {
        grid-gap: 20px;
    }
}

.c-pods__layout {
    @include layout-wrapper;
}

.c-pods__heading {
    @include control__heading;
}

.c-pod,
.c-pod-noimage {
    position: relative;
    display: block;
    height: 285px;
}

.c-pod__body,
.c-pod-noimage__body {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;
    color: $white;
    padding: $ssu;

    @include bp("medium") {
        padding: $bsu;
    }
}

.c-pod__heading,
.c-pod-noimage__heading {
    display: block;
    padding-right: calc(1em + #{$tsu});
    margin-top: $bsu;

    @include font-heading-18b;

    @include bp("large") {
        //@include font-heading-22b;
    }
}

// IMAGE POD
.c-pod__figure {
    @include figure;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:hover,
    &:focus {
        .c-pod__image {
            transform: translate(-50%, -50%) scale(1.05);
        }
    }
}

.c-pod__image {
    position: absolute;
    left: 50%;
    top: 50%;
    width: auto;
    min-width: 101%;
    min-height: 101%;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease-in-out;
    // Override o-fit styles
    height: 100%;
    padding-bottom: 0 !important;
}

.c-pod__image--mobile {
    @include bp($pod-bp) {
        display: none;
    }
}

.c-pod__image--desktop {
    @include bp-below($pod-bp) {
        display: none;
    }
}

.c-pod__figure {
    &:hover,
    &:focus {
        .c-pod__body {
            background-color: transparent;
        }
    }
}

.c-pod__text {
    max-height: 0;
    overflow: hidden;
    margin: 0;
    transition: 0.4s ease;
    transition-property: max-height;
}

.c-pod-hover,
.c-pod__figure:focus {
    .c-pod__text {
        max-height: 200px;
    }
}

.c-pod__pod-shadow {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(to top, rgba($black, 0.95) 0%, rgba($grey--darkest, 0) 100%);
    background: linear-gradient(to top, var(--primary-a) 0%, transparent 100%);
}

// NO IMAGE POD
.c-pod-noimage {
    background: $brand;
}

a.c-pod-noimage {
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background: darken($brand, 10);
    }
}

.c-pod-noimage__body {
    padding: $bsu;
    color: $white;
}
