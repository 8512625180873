﻿.c-pathway-carousel {
    margin-bottom: ($lsu * 2);
    background: #F9F9F9;
    padding: $hsu 0;

    @include bp("medium") {
        margin-bottom: $lsu;
    }

    header {
        height: 60px;
    }

    .flickity-prev-next-button {
        top: auto;
        bottom: -60px;
        margin: 0 0 -30px 0;
        border-radius: 50%;
        height: 36px;
        width: 36px;
        transition: all 0.3s;
        line-height: 36px;
        text-align: center;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @include bp("medium") {
            top: 0%;
            margin-top: -35px;
        }

        &:hover {
            background-color: #01923e;
            transition: all 0.3s;
        }

        &:before {
            text-align: center;
            margin: 0 auto;
            padding: 4px;
        }
    }

    .flickity-prev-next-button.previous {
        left: auto;
        right: 50px;
    }
}

.c-pathway-carousel__heading,
.c-pathway-carousel__link {
    display: inline-block;
}

.c-pathway-carousel__link {
    @include underline-animate;
    margin-left: $bsu;
    color: $black;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    transition: all 0.3s;
    padding-right: 20px;

    &:hover {
        color: #01923e;
        transition: all 0.3s;
    }

    &::before {
        @include font-icon;
        content: $iconf-arrow-right;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(-50%,-50%);
        color: $green;
    }

    @include bp("small") {
        margin-left: $hsu;
    }
}

.c-pathway-carousel__item-list {
    height: auto;

    > div {
        height: 100%;
        width: 100%;

        @include bp("small") {
            width: calc(100% + 10px);
        }

        @include bp("large") {
            width: calc(100% + 20px);
        }
    }

    .c-pathway-carousel__item {
        width: 100%;
        height: auto;
        padding-right: 0;

        @include bp("small") {
            width: 50%;
            padding-right: $bsu;
        }

        @include bp("large") {
            width: 50%;
        }

        @include bp("extra-large") {
            width: 25%;
            padding-right: $lsu;
        }
    }
}

.c-pathway-carousel__item-wrapper {
    border-bottom: 1px solid #ccc;
    padding-bottom: $bsu;
}

.c-pathway-carousel__item-logo-wrapper {
    height: 100px;
    margin-bottom: $bsu;

    .c-pathway-carousel__item-logo {
        display: block;
        width: 100%;

        img {
            width: 100%;
            height: 100px;
            object-fit: contain;
        }
    }
}

.c-pathway-carousel__item-icon-wrapper {
    height: 60px;
    margin-bottom: $bsu;

    .c-pathway-carousel__item-icon {
        display: block;
        width: 100%;
        max-width: 60px;
        max-height: 60px;

        img {
            width: 100%;
            height: 60px;
            width: 60px;
            object-fit: contain;
        }
    }
}

.c-pathway-carousel__item-image-wrapper {
    height: 200px;
    margin-bottom: $bsu;
    overflow: hidden;

    .c-pathway-carousel__item-image {
        display: block;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
    }
}

.c-pathway-carousel__item-heading {
    @include font-heading-24b;
    margin-bottom: $bsu;
}

.c-pathway-carousel__item-text {
    @include font-base-14;
    margin-bottom: $bsu;

    @include bp("medium") {
        margin-bottom: $lsu;
    }
}

.c-pathway-carousel__item-link {
    @include link-transition;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    @include underline-animate;
}

.flickity-enabled:focus {
    outline: none;
}
