// COMPONENT.FOOTER

@use "sass:math";

$footer-logo-width: 150px;

.c-footer {
    position: relative;
    background-color: $black;
}

.c-footer__layout {
    @include layout-wrapper;
}

.c-footer__primary {
    padding: $lsu 0 $bsu;

    @include bp("medium") {
        @include flex-extend;
    }
}

.c-footer__secondary {
    padding: 25px 0 75px;
    background-color: #05212F;

    @include bp("medium") {
        padding: 25px 0;
    }
}

.c-footer__primary-subsection {
    width: 100%;
    margin-bottom: $bsu;

    @include bp("medium") {
        width: 50%;
        margin-bottom: 0;
    }
}

.c-footer__details {
    text-align: center;

    @include bp("medium") {
        text-align: left;
        padding-bottom: $bsu;
        height: calc(100% - 40px);
        margin: 0;
    }

    .c-footer__details-heading {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: $white;
        margin: 0 auto 15px;

        @include bp("medium") {
            margin: 0 0 15px;
        }
    }

    .c-footer__details-text {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        max-width: 220px;
        margin: 0 auto 15px;

        @include bp("medium") {
            margin: 0 0 15px;
        }

        a {
            color: #fff;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.c-footer__signup {
    padding: $bsu 0;
}

.c-footer__primary-links {
    display: block;

    @include bp("medium") {
        display: flex;
        justify-content: space-between;
    }
}

.c-footer__navigation {
    @include list-reset;

    @include bp("medium") {
    }

    @include bp("large") {
    }
}

.c-footer__navigation-link {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    display: block;
    padding: $tsu 0 $ssu;
    color: $white;
    text-align: center;
    margin-bottom: $ssu;

    @include bp("medium") {
        text-align: left;
    }

    &:focus,
    &:hover {
        text-decoration: underline;
    }
}

.c-footer__social {
    width: 160px;
    margin: 0 auto $lsu;

    @include bp("medium") {
        width: 100%;
        margin: 0 0 $lsu;
        flex: 0 0 auto;
        width: calc(50% - #{math.div($footer-logo-width, 2)});
    }
}

.c-footer__social-heading {
    display: block;
    margin-bottom: $ssu;
    color: $white;
}

.c-footer__social-list {
    @include list-reset;
    @include flex-extend;
    width: auto;
    margin: 0 auto;

    @include bp("medium") {
        margin: 0;
    }
}

.c-footer__social-item {
    display: inline-block;
    margin-right: $bsu;

    &:last-child {
        margin: 0;
    }
}

.c-footer__social-link {
    @include icon-wrapper;

    @include square(40px);
    @include link-transition;
    font-size: 20px;
    color: $black;
    background-color: $white;
    border-radius: 50%;

    &:hover,
    &:focus {
        color: $black;
        background-color: $green;
    }
}

.c-footer__legal {
    text-align: center;
    display: block;

    @include bp("medium") {
        @include flex-extend;
        align-items: center;
        justify-content: space-between;
    }
}

.c-footer__legal-copyright {
    @include font-base-12;
    display: block;
    margin-bottom: $ssu;
    color: #DEDEDE;

    @include bp("medium") {
        display: inline;
        margin: 0;
    }
}

.c-footer__legal-links {
    @include list-reset;
}

.c-footer__legal-links-item {
    display: inline-block;
    padding: 0 $tsu;
}

.c-footer__legal-link {
    @include font-base-12;
    color: $white;

    &:focus,
    &:hover {
        text-decoration: underline;
    }
}

.c-footer__tb {
    @include font-base-12;
    display: block;
    text-align: center;
    color: $white;

    &:focus,
    &:hover {
        text-decoration: underline;
    }
}

.c-footer__back-to-top-wrapper {
    @include layout-wrapper;
    text-align: right;
}

.c-footer__back-to-top {
    display: block;
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: $ssu 0;
    z-index: $z-back-to-top;
    width: 54px;
    height: 54px;
    font-size: 32px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s linear,visibility 0s linear 0.5s;
    border-radius: 50%;

    &::before {
        @include font-icon;
        content: $iconf-arrow-up;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    &.fade-in {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }

    @include bp("large") {
        position: relative;
        display: none;
        bottom: 0;
        vertical-align: bottom;
        border-bottom: 0;
        height: 30px;
        opacity: 1;
        visibility: visible;

        &.sticky-on-desktop {
            display: inline-block;
            opacity: 0;
            visibility: hidden;

            &.fade-in {
                position: fixed;
                bottom: 10px;
                top: auto;
                right: 30px;
                width: 54px;
                height: 54px;
                border: none;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.c-footer__primary-sponsors {
    max-width: 150px;
    margin: $bsu auto 0;

    .c-footer__primary-sponsors-heading {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #FFFFFF;
        margin-bottom: $bsu;
        text-align: center;
    }

    .c-footer__primary-sponsors-image {
        width: 100%;
        margin-bottom: $bsu;
    }

    @include bp("medium") {
        margin:0;
    }
}


