﻿// CONTROL.FAQ

.c-faq {
	@include control;
}

.c-faq__layout {
	@include layout-wrapper;
}

.c-faq__heading {
	@include control__heading;
}

.c-faq__list {
	@include list-reset;
	@include list-stacked;
}

.c-faq__item {
	@include ui-border("all");
	@include radius;

	margin-bottom: $tsu;
	box-shadow: none;

	transition-property: box-shadow, border;
	transition-duration: 0.25s;
	transition-timing-function: ease-in-out;

	&[aria-expanded="false"] {
		&:hover,
		&:focus {
			border-color: $brand;
			box-shadow: 0 2px 4px 0 rgba($black, 0.1);
		}
	}
}

.c-faq__item-header {
	@include link-transition;
	position: relative;
	padding: ($ssu * 1.5) $bsu;
	padding-right: $bsu * 3;
	cursor: pointer;

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-faq__item-heading {
	@include font-base-16b;
}

.c-faq__item-icon {
	position: absolute;
	top: 50%;
	right: $bsu;
	font-size: 24px;
	transform: translateY(-50%);
	transition: transform 0.3s linear;

	&::before {
		@include font-icon;
		content: $iconf-arrow-down;
	}

	.c-faq__item.is-expanded &,
	.c-faq__item[aria-expanded="true"] & {
		&::before {
			transform: translateY(-50%) rotate(180deg);
		}
	}
}

.c-faq__item-body {
	padding: $ssu $bsu $bsu;

	.js & {
		display: none;
	}

	.c-faq__item.is-expanded &,
	.c-faq__item[aria-expanded="true"] & {
		display: block;
	}
}

.c-faq__item-text {
	@include body-text;
}
